module.exports = {
  QUALITIES_OPTIONS: [
    { value: 0, text: "---" },
    { value: 5, text: "Legendary" },
    { value: 4, text: "Epic" },
    { value: 3, text: "Rare" },
    { value: 2, text: "Good" },
    { value: 1, text: "Normal" },
  ],
  CHIPSET_TYPE_OPTIONS: [
    { value: 1, text: "Intelligence" },
    { value: 2, text: "Agility" },
    { value: 3, text: "Luck" },
    { value: 4, text: "Power" },
  ],
  QUALITIES: {
    NORMAL: 1,
    GOOD: 2,
    RARE: 3,
    EPIC: 4,
    LEGENDARY: 5,
  },
  QUALITY_NAMES: ['Normal', 'Good', 'Rare', 'Epic', 'Legendary'],
}
