<template>
  <div class="page-content">
    <page-breadcrumb title="Chipset Images" class="mb-3" />

    <b-card>
      <div class="d-flex">
        <data-create-popup class="ml-auto" :default-data="{ url: null, type: null, level: null }"
          :schema="create_img_schema"
          :create-data-fn="createImg" @create="$refs.image_list.getList()"
        />
      </div>
      <data-table-ssr id="image_list" ref="image_list" :limit-base="9" pagination
        :columns="image_table_fields" :get-list-fn="getList"
        :edit-row-fn="updateImage" :delete-row-fn="deleteImage"
      />
    </b-card>

  </div>
</template>

<script>
import service from "../service";
import { CHIPSET_TYPE_OPTIONS } from "@/constants"
import _ from 'lodash'

const image_table_fields = [
  { label: 'Image', field: 'url', input_type: 'image', editable: true, filterOptions: { enabled: true, placeholder: '---' } },
  {
    label: 'Type', field: 'type', input_type: 'select', editable: true, options: CHIPSET_TYPE_OPTIONS,
    filterOptions: { enabled: true, filterDropdownItems: CHIPSET_TYPE_OPTIONS, placeholder: '---' },
  },
  { label: 'Level', field: 'level', editable: true, filterOptions: { enabled: true, placeholder: '---' } },
];

const create_img_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Image', field: 'url', input_type: 'image' }
    ],
  },
  {
    cols: 8,
    fields: [
      { label: 'Type', field: 'type', input_type: 'select', options: CHIPSET_TYPE_OPTIONS, validate: { required: true } },
      { label: 'Level', field: 'level', input_type: 'number', validate: { type: 'number', min: 0, required: true } },
    ],
  }
];

export default {
  data() {
    return {
      image_table_fields,
      create_img_schema,
      filter: {},
    };
  },
  watch: {
    filter: {
      handler() {
        this.$refs.image_list.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList({ limit, page, query, searchTerm }) {
      let filter = _.omitBy(this.filter, (value) => value === null);
      let response = await service.getList({
        query: JSON.stringify({ ...query, ...filter }),
        page, limit
      });
      let list = [], total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
        this.total = total;
      }
      return { list, total };
    },

    async createImg(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async updateImage(row) {
      await service.update({ data: JSON.stringify(row) });
    },
    async deleteImage({id}) {
      await service.delete({id});
      this.$refs.image_list.getList();
    },

  },
};
</script>
